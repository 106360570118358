// ============================================================ //
// lighten color
// 指定したカラーの輝度を20％ or 50%上げる
// ============================================================ //

function lightenColor(hex, rate) {
  let rgb = HEXtoRGB(hex);

  let hsl = RGBtoHSL(rgb);
  hsl[2] += rate;

  let result = HSLtoRGB(hsl);

  // 変換後のRGB値の中に255以上の値を含む（存在しないカラーコード）の場合、変換前の色を返す
  let checkColor = result.filter((color) => color > 255);
  if (checkColor.length != 0) {
    result = rgb;
  }

  return result;
}
// ============================================================ //
// HEX to RGB
// ============================================================ //

function HEXtoRGB(hex) {
  let r = parseInt(hex.substr(1, 2), 16);
  let g = parseInt(hex.substr(3, 2), 16);
  let b = parseInt(hex.substr(5, 2), 16);

  return [r, g, b];
}

// ============================================================ //
// RGB to HSL
// ============================================================ //

function RGBtoHSL(rgb) {
  let r = rgb[0];
  let g = rgb[1];
  let b = rgb[2];

  let max = Math.max(r, g, b);
  let min = Math.min(r, g, b);

  let h = 0;
  switch (max) {
    case min:
      h = 0;
      break;

    case r:
      h = 60 * ((g - b) / (max - min));
      break;

    case g:
      h = 60 * ((b - r) / (max - min)) + 120;
      break;

    case b:
      h = 60 * ((r - g) / (max - min)) + 240;
      break;
  }
  if (h < 0) {
    h = h + 360;
  }

  let cnt = (max + min) / 2;
  let s = 0;
  // minとmaxが等しくないとき、sを計算する（minとmaxが等しいときは、計算上ゼロ同士の除算となりNaNとなるため）
  if (min != max) {
    s = cnt <= 127 ? ((max - min) / (max + min)) * 100 : ((max - min) / (510 - max - min)) * 100;
  }
  let l = ((max + min) / 2 / 255) * 100;

  return [h, s, l];
}

// ============================================================ //
// HSL to RGB
// ============================================================ //

function HSLtoRGB(hsl) {
  let h = hsl[0];
  let s = hsl[1];
  let l = hsl[2];
  if (h == 360) {
    h = 0;
  }

  let max = 0;
  let min = 0;

  if (l < 50) {
    max = 2.55 * (l + l * (s / 100));
    min = 2.55 * (l - l * (s / 100));
  } else {
    max = 2.55 * (l + (100 - l) * (s / 100));
    min = 2.55 * (l - (100 - l) * (s / 100));
  }

  let r = 0;
  let g = 0;
  let b = 0;

  if (h < 60) {
    r = max;
    g = (h / 60) * (max - min) + min;
    b = min;
  } else if (h >= 60 && h < 120) {
    r = ((120 - h) / 60) * (max - min) + min;
    g = max;
    b = min;
  } else if (h >= 120 && h < 180) {
    r = min;
    g = max;
    b = ((h - 120) / 60) * (max - min) + min;
  } else if (h >= 180 && h < 240) {
    r = min;
    g = ((240 - h) / 60) * (max - min) + min;
    b = max;
  } else if (h >= 240 && h < 300) {
    r = ((h - 240) / 60) * (max - min) + min;
    g = min;
    b = max;
  } else if (h >= 300 && h < 360) {
    r = max;
    g = min;
    b = ((360 - h) / 60) * (max - min) + min;
  }

  r = Math.round(r);
  g = Math.round(g);
  b = Math.round(b);

  return [r, g, b];
}
